import { EmailTextStatus } from "./model";

export function validateEmailText(emailText?: string): EmailTextStatus {
  if (!emailText) {
    return EmailTextStatus.EMPTY;
  }

  // max Chat GPT context is 4096 tokens, each token is 3 characters approximately, currently we support spliting text up to 3 parts
  if (emailText.length >= 2.5 * 4096 * 3) {
    return EmailTextStatus.TOO_LONG;
  }

  if (emailText.length < 350) {
    return EmailTextStatus.TOO_SHORT;
  }

  return EmailTextStatus.OK;
}
