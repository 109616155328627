import React from "react";
import {
  createContext,
  useContextSelector,
  useContext,
} from "use-context-selector";
import { UseBaseQueryOptions, useMutation, useQueryClient } from "react-query";

import {
  TextDTO,
  MessageAssistantContextState,
  MessageAssistantState,
  TransformMessageRequest,
  PromptRequest,
} from "./model";
import { invalidateUserOnForbiddenError, useIsAIAuthorized } from "../shared";
import { useApi } from "shared/api";
import { config } from "shared/config";
import { useShowError } from "shared/notifications";
import { removeQuotesFromTextResponse } from "./utils";

const initialState: MessageAssistantContextState = {
  state: MessageAssistantState.Closed,
  AIPermitted: false,
  changeState: () => {},
  isLoading: false,
  fixSpelling: () => {},
  spellingEnabled: true,
  setSpellingEnabled: () => {},
  transformMessage: () => {},
  sendPrompt: () => {},
};

const MessageAssistantContext = createContext(initialState);

export type MessageAssistantProps = {
  children: React.ReactNode;
};

export const MessageAssistantProvider = ({
  children,
}: MessageAssistantProps) => {
  const AIPermitted = useIsAIAuthorized();

  const [state, setState] = React.useState<MessageAssistantState>(
    initialState.state
  );

  const [spellingEnabled, setSpellingEnabled] = React.useState<boolean>(true);

  const queryClient = useQueryClient();
  const { post } = useApi();
  const showError = useShowError();

  const { mutate: mutateSpelling, isLoading: spellingLoading } = useMutation(
    post<TextDTO, TextDTO>(config.endpoints.ai.checkSpelling)
  );

  const { mutate: mutateTransform, isLoading: transformLoading } = useMutation(
    post<TransformMessageRequest, TextDTO>(config.endpoints.ai.transform, {
      transformResponse: removeQuotesFromTextResponse,
    })
  );

  const { mutate: mutatePrompt, isLoading: promptLoading } = useMutation(
    post<PromptRequest, TextDTO>(config.endpoints.ai.prompt, {
      transformResponse: removeQuotesFromTextResponse,
    })
  );

  const isLoading = spellingLoading || transformLoading || promptLoading;

  const fixSpelling = React.useCallback(
    (text: string, opts?: UseBaseQueryOptions<TextDTO>) => {
      if (isLoading) {
        return;
      }

      mutateSpelling(
        { text },
        {
          ...opts,
          onError: (error: unknown) => {
            showError("Failed to fix spelling");
            invalidateUserOnForbiddenError(error, queryClient);
            opts?.onError?.(error);
          },
          onSuccess: ({ text: result }) => {
            setSpellingEnabled(false);
            opts?.onSuccess?.({ text: result });
          },
        }
      );
    },
    [mutateSpelling, isLoading]
  );

  const transformMessage = React.useCallback(
    (data: TransformMessageRequest, opts?: UseBaseQueryOptions<TextDTO>) => {
      if (isLoading) {
        return;
      }

      mutateTransform(data, {
        ...opts,
        onError: (error: unknown) => {
          showError("Failed to transform message");
          invalidateUserOnForbiddenError(error, queryClient);
          opts?.onError?.(error);
        },
      });
    },
    [mutateTransform, isLoading]
  );

  const sendPrompt = React.useCallback(
    (data: PromptRequest, opts?: UseBaseQueryOptions<TextDTO>) => {
      if (isLoading) {
        return;
      }

      mutatePrompt(data, {
        ...opts,
        onError: (error: unknown) => {
          showError("AI Prompt failed");
          invalidateUserOnForbiddenError(error, queryClient);
          opts?.onError?.(error);
        },
      });
    },
    [mutatePrompt, isLoading]
  );

  const context: MessageAssistantContextState = React.useMemo(
    () => ({
      state,
      isLoading,
      AIPermitted,
      changeState: setState,
      fixSpelling,
      spellingEnabled,
      setSpellingEnabled,
      transformMessage,
      sendPrompt,
    }),
    [
      state,
      isLoading,
      AIPermitted,
      setState,
      fixSpelling,
      spellingEnabled,
      setSpellingEnabled,
      transformMessage,
      sendPrompt,
    ]
  );
  return (
    <MessageAssistantContext.Provider value={context}>
      {children}
    </MessageAssistantContext.Provider>
  );
};

export const useMessageAssistantContext = () =>
  useContext(MessageAssistantContext);

export const useMessageAssistantState = () =>
  useContextSelector(MessageAssistantContext, ({ state }) => state);
