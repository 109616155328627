import React from "react";
import {
  createContext,
  useContext,
  useContextSelector,
} from "use-context-selector";

import {
  useSessionStorage,
} from "shared/utils";
import {
  AuthContextState,
  AuthData,
  AuthDataType,
  initialAuthData,
} from "../types";

const initialState: AuthContextState = {
  isAuthenticated: false,
  authData: initialAuthData,
  setAuthData: () => {},
};

const PublicChatAuthContext = createContext(initialState);

export const PublicChatAuthProvider: React.FC = ({ children }) => {
  const [authData, setAuthData] = useSessionStorage<AuthData>(
    AuthDataType.PublicChat,
    initialAuthData
  );

  return (
    <PublicChatAuthContext.Provider
      value={{
        authData,
        isAuthenticated: !!authData.accessToken,
        setAuthData,
      }}
    >
      {children}
    </PublicChatAuthContext.Provider>
  );
};

export const useIsAuthenticatedForPublicChat = () =>
  useContextSelector(PublicChatAuthContext, ({ isAuthenticated }) => isAuthenticated);

export const useSetPublicChatAuthData = () =>
  useContextSelector(PublicChatAuthContext, ({ setAuthData }) => setAuthData);

export const usePublicChatAuthContext = () => useContext(PublicChatAuthContext);

export const usePublicChatLogout = () => {
  const setAuthData = useSetPublicChatAuthData();

  return () => setAuthData(initialAuthData);
};
