import { Dict } from "mixpanel-browser";
import { useMixpanel } from "../context";
import { AnalyticsEvent } from "../model";

export function useTrackEvents() {
  const mixpanel = useMixpanel();

  return (eventName: AnalyticsEvent, opts?: Dict) => {
    try {
      mixpanel.track(eventName, opts);
    } catch (error) {
      console.error("Mixpanel error:", error);
    }
  };
}
