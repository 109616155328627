export const initialAuthData: AuthData = {
  accessToken: "",
  accessTokenExpiresAt: "",
  refreshToken: "",
  refreshTokenExpiresAt: "",
};

export type AuthController = {
  setAuthData: (data: AuthData) => void;
  getAuthData: () => AuthData;
  authData: AuthData;
  isAuthenticated: boolean;
};

export enum AuthDataType {
  App = "appAuthData",
  PublicChat = "publicChatAuthData",
}

export enum AuthProviderType {
  Google = "google",
  Apple = "apple",
  Firebase = "firebase",
}

export type AuthenticateResponse = {
  accessToken: string;
  accessTokenExpiresAt: string;
  refreshToken: string;
  refreshTokenExpiresAt: string;
};

export type AuthData = AuthenticateResponse;

export type AuthContextState = {
  authData: AuthData;
  setAuthData: (val: AuthenticateResponse) => void;
  isAuthenticated: boolean;
};

export type AuthenticateRequest = {
  authProvider: AuthProviderType;
  token: string;
  name?: string | null;
};

export type RegisterRequest = {
  name: string;
  password: string;
  email: string;
  invitationId?: string | null;
};

export type RegisterResponse = {
  status: 201;
  message: string;
  userId: string;
};

export type LoginRequest = {
  password: string;
  email: string;
};

export type ResetPasswordResponse = {
  status: 201;
  message: string;
  email: string;
};

export type ResetPasswordRequest = {
  email: string;
};

export type ChangePasswordResponse = {
  status: 200;
  message: string;
};

export type ChangePasswordRequest = {
  password: string;
  code: string;
};

export enum LocalAuthError {
  SocialLogin = "SocialLogin",
  Unauthorized = "Unauthorized",
  LoginDisabled = "LoginDisabled",
}

export type NotAuthorizedResponse = {
  status: 401;
};
