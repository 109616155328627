import { useTrackEvents } from ".";
import { isImage } from "shared/utils";
import { AnalyticsEvent } from "../model";

export function useTrackFile(type: "download" | "send" = "send") {
  const trackEvent = useTrackEvents();

  return (opts: { name: string; size: number; type: string }) => {
    const image = isImage(opts.type);

    if (image) {
      if (type === "send") {
        trackEvent(AnalyticsEvent.attachment_picture_added, opts);
      } else {
        trackEvent(AnalyticsEvent.attachment_picture_downloaded, opts);
      }
    } else {
      if (type === "send") {
        trackEvent(AnalyticsEvent.attachment_file_added, opts);
      } else {
        trackEvent(AnalyticsEvent.attachment_file_downloaded, opts);
      }
    }
  };
}
