import SpellcheckIcon from "@mui/icons-material/Spellcheck";

import { IconButton, LoadingIndicator, MessageInput } from "shared/components";
import { colors } from "shared/theme";
import { ChatMessageBox } from "shared/chat/chat-box/model";
import { useMessageAssistantContext } from "./MessageAssistant.context";
import { ChangeEvent } from "react";

export const CheckSpelling: ChatMessageBox = ({
  messageProps: { message, onChange, onPaste, setMessage },
  inputProps,
}) => {
  const { isLoading, fixSpelling, setSpellingEnabled, spellingEnabled } =
    useMessageAssistantContext();

  const handleSpelling = () => {
    fixSpelling(message, {
      onSuccess: ({ text }) => setMessage(text),
    });
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSpellingEnabled(true);
    onChange(event);
  };

  return (
    <MessageInput
      value={message}
      onChange={handleChange}
      onPaste={onPaste}
      disabled={isLoading}
      inputRef={inputProps.ref}
      label="Fix Spelling"
      InputLabelProps={{
        shrink: true,
      }}
      placeholder="Type a message..."
      actions={
        <IconButton
          icon={(props) =>
            !isLoading ? (
              <SpellcheckIcon {...props} sx={{ color: colors.blueAccent }} />
            ) : (
              <LoadingIndicator size={20} color={colors.blueAccent} />
            )
          }
          color="transparent"
          label="check spelling"
          disabled={isLoading || !message || !spellingEnabled}
          onClick={handleSpelling}
        />
      }
    />
  );
};
