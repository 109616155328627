import { QueryClient } from "react-query";
import { isApiError, QueryKey } from "shared/api";

export function invalidateUserOnForbiddenError(
  error: unknown,
  queryClient: QueryClient
) {
  if (isApiError(error) && error.status === 403) {
    queryClient.invalidateQueries(QueryKey.User);
  }
}
