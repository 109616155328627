import { AnalyticsEvent } from "../model";
import { useTrackEvents } from "./useTrackEvents";

const emailEvents = {
  discuss: AnalyticsEvent.inbox_discuss,
  archive: AnalyticsEvent.inbox_archive,
  open: AnalyticsEvent.inbox_open_mail,
};
export const useTrackEmailAction = () => {
  const trackEvent = useTrackEvents();

  return ({ action }: { action: "discuss" | "archive" | "open" }) => {
    trackEvent(emailEvents[action]);
  };
};
