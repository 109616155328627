import React from "react";
import { AnalyticsEvent } from "../model";
import { useTrackEvents } from "./useTrackEvents";

export function useTrackEmailOpen() {
  const trackEvent = useTrackEvents();

  React.useEffect(() => {
    return trackEvent(AnalyticsEvent.inbox_open_mail);
  }, []);
}
