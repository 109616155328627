import React from "react";
import { useRequestAccessToken, useLogout } from "shared/auth";
import { config as globalConfig } from "shared/config";
import { createUrl, getDefaultHeaders, handleUnexpectedError } from "./utils";
import { ApiError } from "./api.error";
import { RequestConfig, RequestParams } from "./model";
import { getRequestParams } from ".";
import { QueryFunctionContext } from "react-query";

export const useApi = () => {
  const requestToken = useRequestAccessToken();
  const logout = useLogout();

  const handleError = React.useCallback(async (response: Response) => {
    if (response.status === 401) {
      logout();
    }

    if (response.status >= 400) {
      let body: unknown;

      try {
        body = await response.json();
      } catch (_) {
        throw new ApiError("Unexpected error", response.status);
      }

      throw body;
    }
  }, []);

  const handleJSONResponse = React.useCallback(
    async (response: Response) => {
      await handleError(response);
      return response.json().catch(() => ({}));
    },
    [handleError]
  );

  const get = React.useCallback(
    <TResponse>(url: string, config: RequestConfig<TResponse> = {}) =>
      async (): Promise<TResponse> => {
        const apiUrl = config.apiUrl || globalConfig.apiUrl;
        const token = await requestToken();
        return fetch(
          createUrl({
            base: apiUrl,
            path: url,
            query: config.query,
          }),
          {
            headers: {
              ...getDefaultHeaders(token),
              ...config.headers,
            },
          }
        )
          .then(handleJSONResponse)
          .then(config.transformResponse)
          .catch(handleUnexpectedError);
      },
    [handleJSONResponse, requestToken]
  );

  const getInfinite = React.useCallback(
    <TResponse>(url: string, config: RequestConfig<TResponse> = {}) =>
      async ({ pageParam = 1 }: QueryFunctionContext): Promise<TResponse> => {
        const apiUrl = config.apiUrl || globalConfig.apiUrl;
        const token = await requestToken();
        return fetch(
          createUrl({
            base: apiUrl,
            path: url,
            query: { ...config.query, page: pageParam },
          }),
          {
            headers: {
              ...getDefaultHeaders(token),
              ...config.headers,
            },
          }
        )
          .then(handleJSONResponse)
          .then(config.transformResponse)
          .catch(handleUnexpectedError);
      },
    [handleJSONResponse, requestToken]
  );

  const post = React.useCallback(
    <TBody, TResponse, TError = Error>(
        url: string,
        config: RequestConfig<TResponse> = {}
      ) =>
      async (params: RequestParams<TBody>): Promise<TResponse> => {
        const apiUrl = config.apiUrl || globalConfig.apiUrl;
        const { body, query, replaceParams } = getRequestParams(params);
        const token = await requestToken();

        return fetch(
          createUrl({
            base: apiUrl,
            path: url,
            query: query || config.query,
            replaceParams,
          }),
          {
            method: "POST",
            headers: {
              ...getDefaultHeaders(token),
              ...config.headers,
            },
            body: JSON.stringify(body),
          }
        )
          .then(handleJSONResponse)
          .then(config.transformResponse)
          .catch(handleUnexpectedError);
      },
    [handleJSONResponse, requestToken]
  );

  const patch = React.useCallback(
    <TBody, TResponse, TError = Error>(
        url: string,
        config: RequestConfig<TResponse> = {}
      ) =>
      async (params: RequestParams<TBody>): Promise<TResponse> => {
        const apiUrl = config.apiUrl || globalConfig.apiUrl;
        const { body, query, replaceParams } = getRequestParams(params);
        const token = await requestToken();

        return fetch(
          createUrl({
            base: apiUrl,
            path: url,
            query: query || config.query,
            replaceParams,
          }),
          {
            method: "PATCH",
            headers: {
              ...getDefaultHeaders(token),
              ...config.headers,
            },
            body: JSON.stringify(body),
          }
        )
          .then(handleJSONResponse)
          .then(config.transformResponse)
          .catch(handleUnexpectedError);
      },
    [handleJSONResponse, requestToken]
  );

  const deleteRequest = React.useCallback(
    <TBody, TResponse, TError = Error>(
        url: string,
        config: RequestConfig<TResponse> = {}
      ) =>
      async (params: RequestParams<TBody>): Promise<TResponse> => {
        const apiUrl = config.apiUrl || globalConfig.apiUrl;
        const { body, query, replaceParams } = getRequestParams(params);
        const token = await requestToken();

        return fetch(
          createUrl({
            base: apiUrl,
            path: url,
            query: query || config.query,
            replaceParams,
          }),
          {
            method: "DELETE",
            headers: {
              ...getDefaultHeaders(token),
              ...config.headers,
            },
            body: JSON.stringify(body),
          }
        )
          .then(handleJSONResponse)
          .catch(handleUnexpectedError);
      },
    [handleJSONResponse, requestToken]
  );

  return React.useMemo(() => {
    return {
      get,
      post,
      patch,
      deleteRequest,
      getInfinite,
    };
  }, [get, post, patch, deleteRequest, getInfinite]);
};
