import React from "react";
import { UseBaseQueryOptions, useMutation } from "react-query";
import { useApi } from "shared/api";
import { config } from "shared/config";
import { ResetPasswordRequest, ResetPasswordResponse } from "../types";

export function useResetPassword(options: UseBaseQueryOptions<{}> = {}) {

  const { post } = useApi();

  const { mutate, isLoading, error } = useMutation(
    post<ResetPasswordRequest, ResetPasswordResponse>(
      config.endpoints.auth.resetPassword
    )
  );

  const resetPassword = React.useCallback(
    (email: string) => {
      mutate(
        { email },
        {
          ...options,
        }
      );
    },
    [mutate, options]
  );

  return {
    resetPassword,
    isLoading,
    error,
  };
}
