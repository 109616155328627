export enum QueryKey {
  TwilioToken = "TwilioToken",
  Conversation = "Conversation",
  UserSetup = "UserSetup",
  User = "User",
  UserAvatar = "UserAvatar",
  RefreshAuthToken = "RefreshAuthToken",
  Conversations = "Conversations",
  CollaboratorList = "CollaboratorList",
  IncomingInvitations = "IncomingInvitations",
  OutgoingInvitations = "OutgoingInvitations",
  GmailMessages = "GmailMessages",
  GmailFilters = "GmailFilters",
  GmailMessage = "GmailMessage",
  GmailLabels = "GmailLabels",
  LinkPreview = "LinkPreview",
  EmailSummary = "EmailSummary",
  PublicInvitation = "PublicInvitation",
  MediaList = "MediaList",
}
