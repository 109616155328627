import React from "react";
import { UseQueryResult } from "react-query";

import { LoadingIndicator } from "../components";

type QueryDataWrapperProps<T> = {
  result: Pick<UseQueryResult<T>, "data" | "error" | "isLoading"> & {
    isFetching?: boolean;
  };
  children: ({ data }: { data: T }) => React.ReactNode;
  loadingView?: JSX.Element;
  errorView?: JSX.Element;
  loaderWhenFetching?: boolean;
};

export function QueryDataWrapper<T>({
  result,
  children,
  loadingView = <LoadingIndicator />,
  errorView = <ErrorView />,
  loaderWhenFetching = false,
}: QueryDataWrapperProps<T>) {
  if (result.isLoading || (loaderWhenFetching && result.isFetching)) {
    return loadingView;
  }

  if (result.error) {
    return errorView;
  }

  return <>{result.data !== undefined && children({ data: result.data })}</>;
}

const ErrorView = () => <></>;
