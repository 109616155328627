import React from "react";
import { useMutation, useQueryClient } from "react-query";
import { QueryKey, useApi } from "shared/api";
import { config } from "shared/config";
import { useShowError } from "shared/notifications";
import { invalidateUserOnForbiddenError } from "../shared";
import {
  EmailErrorText,
  EmailSummary,
  EmailSummaryRequest,
  EmailSummaryResponse,
  EmailTextStatus,
} from "./model";
import { validateEmailText } from "./utils";

export function useSummarizeEmail(messageText?: string, messageId?: string) {
  const [emailSummary, setEmailSummary] = React.useState<
    EmailSummary | undefined
  >();

  const queryClient = useQueryClient();
  const showError = useShowError();
  const { post } = useApi();

  const { mutate, isLoading } = useMutation(
    post<EmailSummaryRequest, EmailSummaryResponse>(
      config.endpoints.ai.emailSummary
    ),
    {
      onSuccess: (data) => {
        setEmailSummary(data);
        queryClient.setQueryData([QueryKey.EmailSummary, messageId], data);
      },
      onError: (error) => {
        setEmailSummary({
          description: "We are sorry. Email summary failed",
          items: [],
        });
        invalidateUserOnForbiddenError(error, queryClient);
        showError("Failed to summarize email");
      },
    }
  );

  const summarizeEmail = React.useCallback(() => {
    if (isLoading) {
      return;
    }

    const status = validateEmailText(messageText);

    if (status !== EmailTextStatus.OK) {
      setEmailSummary(undefined);
      return showError(EmailErrorText[status]);
    }

    const cachedData = queryClient.getQueryData<EmailSummary>([
      QueryKey.EmailSummary,
      messageId,
    ]);

    if (cachedData) {
      return setEmailSummary(cachedData);
    }

    mutate({ text: messageText! });
  }, [messageText, mutate, messageId, isLoading]);

  React.useEffect(() => () => setEmailSummary(undefined), []);

  return {
    emailSummary,
    summarizeEmail,
    isLoading: isLoading,
    setEmailSummary,
  };
}

// const getReviewStub = (body: EmailSummaryRequest) => {
//   return new Promise((resolve) =>
//     setTimeout(
//       () =>
//         resolve({
//           description:
//             "This is a review. It is a review of a review. It is a review of a review of a review. It is a review of a review of a review of a review. It is a review of a review of a review of a review of a review. It is a review of a review of a review of a review of a review of a review. It is a review of a review.",
//           points: [
//             "This is a point is a review of a review of a review of a review. It is a review of a review of a review of a review of a review. It is a review of a review of",
//             "This is another point is a review of a review of a review of a review. It is a review of a review of a review of a review of a review. It is a review of a review of",
//             "This is a third point is a review of a review of a review of a review. It is a review of a review of a review of a review of a review. It is a review of a review of",
//             "This is a point is a review of a review of a review of a review. It is a review of a review of a review of a review of a review. It is a review of a review of",
//             "This is another point is a review of a review of a review of a review. It is a review of a review of a review of a review of a review. It is a review of a review of",
//             "This is a third point is a review of a review of a review of a review. It is a review of a review of a review of a review of a review. It is a review of a review of",
//           ],
//         }),
//       2000
//     )
//   ) as Promise<EmailSummaryResponse>;
// };
