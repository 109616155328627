import React from "react";

const clientId = process.env.REACT_APP_APPLE_CLIENT_ID;
const redirectURI = process.env.REACT_APP_APP_URL;

export const useAppleSignIn = () => {
  const [appleAuthLoading, setAppleAuthLoading] = React.useState(false);

  const signInWithApple = async () => {
    setAppleAuthLoading(true);
    return window.AppleID.auth
      .signIn({
        clientId,
        usePopup: true,
        scope: "email name",
        redirectURI,
      })
      .finally(() => {
        setAppleAuthLoading(false);
      });
  };

  return {
    isLoading: appleAuthLoading,
    signInWithApple,
  };
};
