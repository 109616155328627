import SendIcon from "@mui/icons-material/Send";

import { IconButton, LoadingIndicator, MessageInput } from "shared/components";
import { colors } from "shared/theme";
import { ChatMessageBox } from "shared/chat/chat-box/model";
import { useMessageAssistantContext } from "./MessageAssistant.context";
import { ChangeEvent } from "react";
import { AIResponseAttribute } from "./model";
import {
  copyMessage,
  getMessageByIndex,
  updateMessageAttributes,
  useTwilioConversationSelector,
} from "shared/twilio";
import { prepareMessagesHistory } from "./utils";

export const AssistantChat: ChatMessageBox = ({
  isLoading: messageLoading,
  messageProps: { message, onChange, onPaste, sendMessage },
  inputProps,
}) => {
  const {
    isLoading: promptLoading,
    setSpellingEnabled,
    sendPrompt,
  } = useMessageAssistantContext();

  const conversation = useTwilioConversationSelector("twilioConversation");
  const setMessages = useTwilioConversationSelector("setMessages");
  const messages = useTwilioConversationSelector("messages");

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSpellingEnabled(true);
    onChange(event);
  };

  const isLoading = messageLoading || promptLoading;

  const handleMessage = async () => {
    const history = prepareMessagesHistory(messages);
    const index = await sendMessage({ [AIResponseAttribute]: null });

    sendPrompt(
      { prompt: message, history },
      {
        onSuccess: async ({ text }) => {
          const twilioMessage = await getMessageByIndex(index!, conversation);
          if (twilioMessage) {
            await updateMessageAttributes(twilioMessage, {
              [AIResponseAttribute]: text,
            });

            setMessages((messages) =>
              messages.map((m) =>
                m.sid === twilioMessage.sid ? copyMessage(twilioMessage) : m
              )
            );
          }
        },
      }
    );
  };

  return (
    <MessageInput
      value={message}
      onChange={handleChange}
      onPaste={onPaste}
      disabled={isLoading}
      inputRef={inputProps.ref}
      placeholder="Ask question to AI"
      label="AI Chat"
      InputLabelProps={{
        shrink: true,
      }}
      onEnter={handleMessage}
      actions={
        <IconButton
          onClick={handleMessage}
          disabled={isLoading || !message}
          color="transparent"
          icon={(props) =>
            !isLoading ? (
              <SendIcon {...props} sx={{ color: colors.blueAccent }} />
            ) : (
              <LoadingIndicator size={20} color={colors.blueAccent} />
            )
          }
          label="Chat with AI"
        />
      }
    />
  );
};
