import { Stack, styled } from "@mui/material";
import { AppDialog, AppDialogTitle } from "shared/components/AppDialog";
import { GmailMessagePreviewHeaderContent } from "shared/gmail/components/GmailMessagePreviewHeaderContent";
import { QueryDataWrapper } from "shared/api";
import { LoadingIndicator } from "shared/components";
import { EmailSummary } from "./model";
import { Summary } from "./Summary";

type SummarizeEmailDialogProps = {
  open: boolean;
  onClose: () => void;
  isLoading: boolean;
  from: string;
  avatarColor: string;
  subject: string;
  summary?: EmailSummary;
};
export const SummarizeEmailDialog = ({
  open,
  isLoading,
  onClose,
  summary,
  from,
  subject,
  avatarColor,
}: SummarizeEmailDialogProps) => {
  return (
    <AppDialog open={open} onClose={onClose} iosStyle>
      <AppDialogTitle onClose={onClose} title={"Summary"} />
      <Wrapper>
        <GmailMessagePreviewHeaderContent
          from={from}
          subject={subject}
          avatarColor={avatarColor}
          mx={{ xs: 4, sm: 8 }}
        />
        <QueryDataWrapper
          result={{
            isLoading,
            data: summary,
            error: null,
          }}
          loadingView={<LoadingIndicator py={10} />}
        >
          {({ data }) => <Summary summary={data} />}
        </QueryDataWrapper>
      </Wrapper>
    </AppDialog>
  );
};

const Wrapper = styled(Stack)(({ theme: { spacing, breakpoints } }) => ({
  flex: 1,
  overflow: "hidden",
  paddingTop: spacing(4),
  paddingBottom: spacing(4),
  [breakpoints.up("sm")]: {
    paddingTop: spacing(6),
    paddingBottom: spacing(6),
  },
}));
