import { UseBaseQueryOptions, useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { useApi } from "shared/api";
import { config } from "shared/config";
import { useShowError } from "shared/notifications";
import { getConversationPath, paths } from "shared/router";
import { useSetAppAuthData } from "../context/AppAuth";
import { AuthenticateResponse, AuthenticateRequest } from "../types";
import { useAcceptPublicInvitation } from "shared/public-invitation";
import { AnalyticsEvent, useTrackEvent } from "shared/analytics";

export const useAuthenticate = () => {
  const { post } = useApi();

  const navigate = useNavigate();

  const showError = useShowError();

  const setAuthData = useSetAppAuthData();

  const { acceptPublicInvitation } = useAcceptPublicInvitation();

  const trackLogin = useTrackEvent(AnalyticsEvent.user_login);

  const { mutate, isLoading } = useMutation(
    post<AuthenticateRequest, AuthenticateResponse>(config.endpoints.auth.main)
  );

  const authenticate = (
    data: AuthenticateRequest,
    options: UseBaseQueryOptions<AuthenticateResponse> = {}
  ) => {
    mutate(data, {
      ...options,
      onSuccess: async (res) => {
        trackLogin();
        setAuthData(res);
        const accepted = await acceptPublicInvitation();

        if (accepted && accepted.chatSid) {
          navigate(getConversationPath(accepted.chatSid));
        } else {
          navigate({ pathname: paths.home, search: window.location.search });
        }

        options.onSuccess?.(res);
      },
      onError: () => {
        showError("Authentication failed", "Please try again later");
      },
    });
  };

  return {
    authenticate,
    isLoading,
  };
};
