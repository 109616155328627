import React, { ChangeEvent } from "react";
import { Stack } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import MessageOffIcon from "@mui/icons-material/CommentsDisabled";
import MessageIcon from "@mui/icons-material/Message";

import { IconButton, LoadingIndicator, MessageInput } from "shared/components";
import { colors } from "shared/theme";
import { ChatMessageBox } from "shared/chat/chat-box/model";
import { useMessageAssistantContext } from "./MessageAssistant.context";
import { useToggle } from "shared/utils";

export const TransformMessage: ChatMessageBox = ({
  messageProps: { message, onChange, onPaste, setMessage },
  inputProps,
}) => {
  const [prompt, setPrompt] = React.useState<string>("");
  const [messageAttached, toggleMessageAttached] = useToggle(false);

  const { isLoading, setSpellingEnabled, transformMessage, sendPrompt } =
    useMessageAssistantContext();

  const handleTransform = () => {
    const options = {
      onSuccess: ({ text }: { text: string }) => {
        setMessage(text);
        setSpellingEnabled(true);
        setPrompt("");
        !messageAttached && toggleMessageAttached();
      },
    };

    if (!messageAttached) {
      return sendPrompt({ prompt }, options);
    }

    transformMessage({ text: message, prompt }, options);
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSpellingEnabled(true);
    onChange(event);
  };

  return (
    <Stack width={1} spacing={5}>
      {messageAttached && (
        <MessageInput
          value={message}
          onChange={handleChange}
          onPaste={onPaste}
          disabled={isLoading}
          label="Your Message"
          InputLabelProps={{
            shrink: true,
          }}
          placeholder="Type a message..."
          helperText={false}
          maxRows={3}
        />
      )}
      <MessageInput
        maxRows={3}
        disabled={isLoading}
        inputRef={inputProps.ref}
        value={prompt}
        onEnter={handleTransform}
        onChange={(e) => setPrompt(e.target.value)}
        label="AI Prompt"
        InputLabelProps={{
          shrink: true,
        }}
        placeholder={
          messageAttached
            ? "Describe how to transform your message"
            : "Describe what you want from AI"
        }
        actions={
          <>
            <IconButton
              icon={(props) =>
                messageAttached ? (
                  <MessageOffIcon
                    {...props}
                    sx={{ color: colors.blueAccent }}
                  />
                ) : (
                  <MessageIcon {...props} sx={{ color: colors.blueAccent }} />
                )
              }
              color="transparent"
              label={
                messageAttached ? "Detatch your message" : "Attach your message"
              }
              onClick={toggleMessageAttached}
            />
            <IconButton
              icon={(props) =>
                !isLoading ? (
                  <SendIcon {...props} sx={{ color: colors.blueAccent }} />
                ) : (
                  <LoadingIndicator size={20} color={colors.blueAccent} />
                )
              }
              color="transparent"
              label="Send prompt"
              disabled={isLoading || !prompt || (messageAttached && !message)}
              onClick={handleTransform}
            />
          </>
        }
      />
    </Stack>
  );
};
