import React from "react";
import mixpanel from "mixpanel-browser";
import { createContext, useContextSelector } from "use-context-selector";
import { useUser } from "../../user";
import { AnalyticsContextState } from "../model";

const initialContext: AnalyticsContextState = {
  mixpanel: mixpanel,
};

const AnalyticsContext = createContext(initialContext);

export const AnalyticsProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { user } = useUser();
  React.useEffect(() => {
    try {
      mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN ?? "", {
        debug: process.env.NODE_ENV === "development",
      });
    } catch (error) {
      console.error("Mixpanel error:", error);
    }
  }, []);

  React.useEffect(() => {
    if (!user.id) {
      return;
    }
    try {
      mixpanel.identify(user.id);

      mixpanel.register({
        publicChatUser: !user.email,
        userName: user.name,
        userEmail: user.email,
        userId: user.id,
      });

      mixpanel.people.set_once("name", user.name);
      mixpanel.people.set_once("id", user.id);
      mixpanel.people.set_once("email", user.email);
    } catch (error) {
      console.error("Mixpanel error:", error);
    }
  }, [user]);

  const context = React.useMemo(() => ({ mixpanel }), []);

  return (
    <AnalyticsContext.Provider value={context}>
      {children}
    </AnalyticsContext.Provider>
  );
};

export const useMixpanel = () =>
  useContextSelector(AnalyticsContext, ({ mixpanel }) => mixpanel);
