import { OverridedMixpanel } from "mixpanel-browser";

export type AnalyticsContextState = {
  mixpanel: OverridedMixpanel;
};

export enum AnalyticsEvent {
  welcome_googleSignIn_tap = "welcome_googleSignIn_tap",
  welcome_appleSignIn_tap = "welcome_appleSignIn_tap",

  user_login = "user_login",
  public_chat_user_login = "public_chat_user_login",
  discuss_chat_loaded = "discuss_chat_loaded",

  inbox_discuss = "inbox_discuss",
  inbox_archive = "inbox_archive",
  inbox_open_mail = "inbox_open_mail",


  discuss_create_chat = "discuss_create_chat",
  discuss_open_chat = "discuss_open_chat",
  discuss_edited_subject = "discuss_edited_subject",
  discuss_remove_chat = "discuss_remove_chat",
  discuss_left_chat = "discuss_left_chat",

  public_chat_enable_on_create = "public_chat_enable_on_create",
  public_chat_enable_exisitng = "public_chat_enable_exisitng",
  public_chat_disable_existing = "public_chat_disable_existing",
  public_chat_copy = "public_chat_copy",

  email_relay_copy = "email_relay_copy",
  universal_invitation_copy = "universal_invitation_copy",

  collaborator_added_existing_discussion = "collaborator_added_existing_discussion",
  collaborator_added_new_discussion = "collaborator_added_new_discussion",
  collaborator_removed_existing_discussion = "collaborator_removed_existing_discussion",
  collaborator_removed_new_discussion = "collaborator_removed_new_discussion",

  apple_account_connected = "apple_account_connected",

  google_account_connected = "google_account_connected",

  gmail_account_connected = "gmail_account_connected",

  profile_settings = "profile_settings",

  user_invited = "user_invited",
  accept_invitation = "accept_invitation",
  reject_invitation = "reject_invitation",

  chat_send_message = "chat_send_message",

  attachment_picture_added = "attachment_picture_added",
  attachment_file_added = "attachment_file_added",
  attachment_picture_downloaded = "attachment_picture_downloaded",
  attachment_file_downloaded = "attachment_file_downloaded",

  logout = "logout",
}
