import { Box } from "@mui/material";
import SmartToyIcon from "@mui/icons-material/SmartToy";
import CloseIcon from "@mui/icons-material/Close";
import { ProButton, IconButton } from "shared/components";
import { useMessageAssistantContext } from "./MessageAssistant.context";
import { MessageAssistantState } from "./model";

export const AssistantButton = () => {
  const { state, changeState, isLoading, AIPermitted } =
    useMessageAssistantContext();

  const open = state !== MessageAssistantState.Closed;

  const onClick = () => {
    const targetState = open
      ? MessageAssistantState.Closed
      : MessageAssistantState.Chat;

    changeState(targetState);
  };

  if (!AIPermitted) return null;

  const Component =
    state === MessageAssistantState.Closed ? ProButton : IconButton;

  return (
    <Box sx={{ position: "absolute", top: -20, right: 15, zIndex: 2 }}>
      <Component
        color="secondary"
        icon={open ? CloseIcon : SmartToyIcon}
        label={open ? "Close assistant" : "Open assistant"}
        disabled={isLoading}
        onClick={onClick}
        size={42}
      />
    </Box>
  );
};