export const Mutex = () => {
  let observers: (() => void)[] = [];
  let isLocked = false;

  const lock = () => {
    isLocked = true;
  };

  const unlock = () => {
    isLocked = false;
    observers.forEach((resolve) => resolve());
    observers = [];
  };

  const waitForUnlock = () => {
    return new Promise<void>((resolve) => {
      if (!isLocked) {
        return resolve();
      }
      observers.push(resolve);
    });
  };

  return {
    get isLocked() {
      return isLocked;
    },
    lock,
    unlock,
    waitForUnlock,
  };
};
