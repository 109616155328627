import SmartToyIcon from "@mui/icons-material/SmartToy";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { TextMessageWrapper } from "shared/chat";

import {
  LoadingIndicator,
  MessageCaption,
  MessageTile,
} from "shared/components";
import { colors } from "shared/theme";

export const AIResponseMessage = ({
  response,
}: {
  response: string | null;
}) => {
  return (
    <MessageTile
      isMine={false}
      avatar={<SmartToyIcon sx={{ color: colors.info.main, fontSize: 32 }} />}
      isFullWidth={false}
      caption={
        <MessageCaption
          isMine={false}
          userName={"Chatbot"}
          isQuote={false}
          isWebUser={false}
          isEdited={false}
        />
      }
    >
      <TextMessageWrapper textAlign={"left"} isMine={false}>
        <Typography
          sx={{
            lineHeight: 1.25,
          }}
          component="div"
        />
        {response ? (
          response
        ) : (
          <>
            <Box>Generating response</Box>
            <Box pt={2}>
              <LoadingIndicator size={20} color={colors.blueAccent} />
            </Box>
          </>
        )}
      </TextMessageWrapper>
    </MessageTile>
  );
};
