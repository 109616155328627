import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  styled,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";

import { colors } from "shared/theme";
import { EmailSummary } from "./model";

export const Summary = ({ summary }: { summary: EmailSummary }) => {
  return (
    <Box flex={1} overflow="auto" px={{ xs: 4, sm: 8 }}>
      <Box py={6}>
        <Description
          variant="body1"
          sx={{ color: colors.haleNavy.dark }}
          px={2}
          my={2}
        >
          {summary.description}
        </Description>
      </Box>
      <StyledList>
        {summary.items?.map((point, i) => (
          <ListItem key={i} disablePadding>
            <ListItemButton>
              <ListItemIcon>
                <CheckIcon sx={{ color: colors.blueAccent }} />
              </ListItemIcon>
              <ListItemText secondary={point} />
            </ListItemButton>
          </ListItem>
        ))}
      </StyledList>
    </Box>
  );
};

const Description = styled(Typography)(({ theme: { spacing, breakpoints } }) => ({
  fontSize: 16,
  padding: spacing(0, 4),
  margin: spacing(2, 0),
  color: colors.haleNavy.main,
  [breakpoints.up("sm")]: {
    textAlign: "center",
    fontSize: 18,
  }
}));

const StyledList = styled(List)(({ theme: { spacing, breakpoints } }) => ({
  " & .MuiTypography-root": {
    color: colors.haleNavy.main,
  },
  [breakpoints.down("sm")]: {
    "& .MuiListItemIcon-root": {
      minWidth: 35,
      "& svg": {
        width: 20,
        height: 20,
      }
    },
    "& .MuiListItemButton-root": {
      paddingLeft: spacing(2),
      paddingRight: spacing(2),
    }

  }
}));

