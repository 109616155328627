import { Message } from "@twilio/conversations";
import { hasEmail } from "shared/twilio";
import {
  HasAIResponse,
  hasAIResponse,
  PromptHistoryEntry,
  TextDTO,
} from "./model";

export const prepareMessagesHistory = (
  messages: Message[]
): PromptHistoryEntry[] => {
  const candidates = messages
    .filter(
      (message) => message.type === "text" && !hasEmail(message.attributes)
    )
    .filter(
      (message) =>
        message.body ||
        (hasAIResponse(message.attributes) &&
          (message.attributes as HasAIResponse).aiResponse !== null)
    )
    .slice(-4);

  let history: PromptHistoryEntry[] = [];

  for (const candidate of candidates) {
    history.push({ role: "user", content: candidate.body! });
    if (hasAIResponse(candidate.attributes)) {
      history.push({
        role: "assistant",
        content: candidate.attributes.aiResponse!,
      });
    }
  }
  return history.slice(-4);
};

export function removeQuotesFromTextResponse({ text }: TextDTO) {
  return { text: text.replace(/^"/, "").replace(/"$/, "") };
}
