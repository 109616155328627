import { Grow, Box, Stack, SvgIconProps } from "@mui/material";
import SpellcheckIcon from "@mui/icons-material/Spellcheck";
import MessageIcon from "@mui/icons-material/TextSnippet";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";

import { Tab } from "shared/components";
import { useMessageAssistantContext } from "./MessageAssistant.context";
import { MessageAssistantState } from "./model";

export const AssistantStateTabs = () => {
  const { state, changeState } = useMessageAssistantContext();

  return (
    <Grow in={state !== MessageAssistantState.Closed} id="aaaa">
      <Stack
        direction="row"
        spacing={0.5}
        sx={{
          position: "absolute",
          zIndex: 5,
          top: 0,
          left: { xs: 8, sm: 32 },
        }}
      >
        <StateTab
          text="Chat"
          state={MessageAssistantState.Chat}
          Icon={QuestionAnswerIcon}
          curentState={state}
          changeState={changeState}
        />
        <StateTab
          text="Message"
          state={MessageAssistantState.Message}
          Icon={MessageIcon}
          curentState={state}
          changeState={changeState}
        />
        <StateTab
          text="Spelling"
          state={MessageAssistantState.Spelling}
          Icon={SpellcheckIcon}
          curentState={state}
          changeState={changeState}
        />
      </Stack>
    </Grow>
  );
};

type StateTabProps = {
  text: string;
  state: MessageAssistantState;
  curentState: MessageAssistantState;
  changeState: (state: MessageAssistantState) => void;
  Icon: React.FC<SvgIconProps>;
};

const StateTab = ({
  text,
  changeState,
  Icon,
  state,
  curentState,
}: StateTabProps) => (
  <Tab upturn active={state === curentState} onClick={() => changeState(state)}>
    <>
      <Box pr={1}>{text}</Box>
      <Icon sx={{ fontSize: 16 }} />
    </>
  </Tab>
);
