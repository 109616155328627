import { Dict } from "mixpanel-browser";
import { useMixpanel } from "../context";
import { AnalyticsEvent } from "../model";

export function useTrackEvent(eventName: AnalyticsEvent) {
  const mixpanel = useMixpanel();

  return (opts?: Dict) => {
    try {
      mixpanel.track(eventName, opts);
    } catch (error) {
      console.error("Mixpanel error:", error);
    }
  };
}
