import React from "react";
import useScript from "react-script-hook";

type AppleAuthContextState = {};

const appleAuthScriptUrl =
  "https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js";
const clientId = process.env.REACT_APP_APPLE_CLIENT_ID;
const redirectURI = process.env.REACT_APP_APP_URL;

const AppleAuthContext = React.createContext(
  null as unknown as AppleAuthContextState
);

export const AppleAuthProvider: React.FC = ({ children }) => {
  const [isLoading] = useScript({ src: appleAuthScriptUrl });

  React.useEffect(() => {
    if (isLoading) {
      return;
    }

    window.AppleID.auth.init({
      clientId,
      usePopup: true,
      scope: "email name",
      redirectURI,
    });
  }, [isLoading]);

  if (isLoading) {
    return <></>;
  }

  return (
    <AppleAuthContext.Provider value={{}}>{children}</AppleAuthContext.Provider>
  );
};
