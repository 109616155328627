import { stringify } from "query-string";
import {
  ReplaceParams,
  RequestBodyWithOptions,
  RequestParams,
  RequestQuery,
} from "./model";

export const handleUnexpectedError = async (error: Error) => {
  if (error.message === "Failed to fetch") {
    throw new Error("Please try again later.");
  }
  throw error;
};

export const getDefaultHeaders = (token: string) => ({
  "Content-Type": "application/json",
  ...(token && { Authorization: `Bearer ${token}` }),
});

export const createUrl = ({
  base,
  path,
  query,
  replaceParams,
}: {
  base: string;
  path: string;
  query?: RequestQuery;
  replaceParams?: ReplaceParams;
}) => {
  const result = new URL(path + createSearchQuery(query), base);

  if (replaceParams) {
    return replaceUrlParams(result.href, replaceParams);
  }

  return result.href;
};

export const createSearchQuery = (query?: RequestQuery) =>
  `${query ? "?" : ""}${stringify(query || {})}`;

export const replaceUrlParams = (target: string, params: ReplaceParams) => {
  let result = target;

  for (const [param, value] of Object.entries(params)) {
    result = result.replace(new RegExp(":" + param, "g"), String(value));
  }

  return result;
};

export const getRequestParams = <T>(
  arg: RequestParams<T>
): { body?: T; query?: RequestQuery; replaceParams?: ReplaceParams } => {
  if (!hasRequestOptions(arg)) {
    return { body: arg };
  }
  
  const { body, requestOptions = {} } = arg;
  const { query, replaceParams } = requestOptions;

  return { body, query, replaceParams };
};

const hasRequestOptions = <T>(
  arg: RequestParams<T>
): arg is RequestBodyWithOptions<T> =>
  typeof arg === "object" && arg !== null && "requestOptions" in arg;
